import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import type React from 'react';
import { useEffect, useRef } from 'react';

import Button from './Button';

const AnimatedSearchButton: React.FC = () => {
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const searchContainer = searchContainerRef.current;
    const searchToggle = searchContainer?.querySelector('[data-search-toggle]');
    const searchInput = searchInputRef.current;

    if (!searchToggle || !searchInput) return;

    const handleToggleClick = (e: Event) => {
      e.stopPropagation();
      searchInput.classList.toggle('expanded');
      if (searchInput.classList.contains('expanded')) {
        searchInput.focus();
      }
    };

    const handleDocumentClick = (event: MouseEvent) => {
      if (!searchContainer?.contains(event.target as Node)) {
        searchInput.classList.remove('expanded');
      }
    };

    const handleFormSubmit = (e: Event) => {
      e.preventDefault();
      const searchQuery = searchInput.value.trim().toLowerCase();
      if (searchQuery) {
        window.location.href = `/search?query=${encodeURIComponent(searchQuery)}`;
      } else {
        searchInput.classList.add('error');
        setTimeout(() => searchInput.classList.remove('error'), 500);
      }
    };

    searchToggle.addEventListener('click', handleToggleClick);
    document.addEventListener('click', handleDocumentClick);
    searchContainer?.querySelector('form')?.addEventListener('submit', handleFormSubmit);

    return () => {
      searchToggle.removeEventListener('click', handleToggleClick);
      document.removeEventListener('click', handleDocumentClick);
      searchContainer?.querySelector('form')?.removeEventListener('submit', handleFormSubmit);
    };
  }, []);

  return (
    <div className='search-container relative inline-flex items-center' ref={searchContainerRef}>
      <Button intent='icon' size='icon' className='search-button z-10' aria-label='Search' data-search-toggle>
        <MagnifyingGlassIcon className='h-4 w-4 text-neutral-950' />
      </Button>
      <form className='search-form absolute right-0 top-0'>
        <input
          type='text'
          className='search-input absolute right-0 top-0 h-9 w-0 rounded-sm border border-neutral-200 bg-white px-2 pl-5 text-sm opacity-0'
          placeholder='Search...'
          ref={searchInputRef}
        />
      </form>
      <style>{`
                .search-button {
                    transition: all 0.3s ease-in-out;
                }

                .search-input {
                    transition:
                        width 0.3s ease-in-out,
                        opacity 0.3s ease-in-out;
                }

                .search-input.expanded {
                    width: 250px;
                    opacity: 1;
                }

                .search-input:focus {
                    outline: none;
                    border-color: #ccc;
                }

                .search-input::placeholder {
                    color: #9ca3af;
                    text-transform: uppercase;
                }

                .search-input:focus + .search-button,
                .search-input:not(:placeholder-shown) + .search-button {
                    background-color: transparent;
                    border-color: transparent;
                }

                .search-input.error {
                    border-color: #ff0000;
                }
            `}</style>
    </div>
  );
};

export default AnimatedSearchButton;
